<template lang="pug">
section#api-tokens
    header
        router-link.back(:to="{ name: 'dashboard' }")
            i.icon.material-icons-outlined.inline-block navigate_before
            span.inline-block Back

    h2.animated.fadeIn My API Tokens
    
    .onboarding.animated.fadeIn(v-if="!apiTokens || !apiTokens.length")
        p You don't have any API Tokens yet. <a v-on:click="newToken()">Add a new one</a> to get started 🙌
        img(:src="onboardingImg")

    .token-container(v-if="apiTokens && apiTokens.length")
        .token-w(v-for="t in apiTokens")
            Token(:token="t")

    .modal-clearfix(v-on:click.self="close", v-if="modal")
        .modal.animated.zoomIn
            p.title Create a new API Token
            
            form(@submit.prevent)
                input(placeholder="My new API token", v-model="newTokenDescription")
                .error(v-if="error") {{ error }}
                .spinner(v-if="loading")
                button(v-if="!loading", @click="postToken") Create

</template>

<script>
import { getAPITokens, postAPIToken } from '@/services/token-service';
import Token from '@/components/Token';

export default {
    components: { Token },
    computed: {
        onboardingImg: () => require('@/assets/img/token_onboarding.png'),
    },
    data: () => ({
        newTokenDescription: undefined,
        error: undefined,
        loading: false,
        modal: false,
        apiTokens: [ null, null, null, null ]
    }),
    async mounted() {
        this._updateTokens();
    },
    methods: {
        async _updateTokens() {
            try {
                this.apiTokens = await getAPITokens();
            } catch (error) {
                console.error(error);
            }
        },
        newToken() {
            this.modal = true;
        },
        close() {
            this.modal = false;
        },
        async postToken() {
            try {
                this.error = undefined;

                if (!this.newTokenDescription || !this.newTokenDescription.length) {
                    throw 'Please, add a description';
                }
                await postAPIToken(this.newTokenDescription);
                
                this._updateTokens();
                this.close();
                this.error = undefined;
            } catch (error) {
                this.error = error;
            }
        }
    }
};
</script>

<style scoped>
header .back {
    padding: var(--padding-base) 0;
    padding-right: var(--padding-base);
    position: relative;
    display: block;
    width: fit-content;
    top: calc(var(--padding-base) * -1);
}
header .back > *  {
    vertical-align: middle;
}
header .back i.icon {
    font-size: var(--font-size-base);
    margin-left: -6px;
}
header .back span {
    text-transform: uppercase;
    font-size: var(--font-size-small);
    font-weight: bold;
}

h2 {
    font-size: var(--font-size-huge);
    margin-bottom: var(--margin-large);
}
.onboarding {
    width: 50%;
    padding-top: var(--padding-large);
    margin: 0 auto;
}

.onboarding img {
    position: relative;
    display: block;
    width: 60%;
    margin: auto;
}
.onboarding p {
    color: var(--text-color-grey);
    font-size: var(--font-size-base);
}
.onboarding a {
    cursor: pointer;
}

.token-container {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
}

.token-w {
    width: calc(33.33% - calc(calc(var(--padding-medium) * 2) / 3));
    margin-bottom: var(--padding-medium);
    margin-right: var(--padding-medium);
    vertical-align: top;
}

@media only screen and (min-width: 1001px) {
    .token-w:nth-of-type(3n) {
        margin-right: 0;
    }
}
@media only screen and (max-width: 1000px) {
    h2 {
        font-size: var(--font-size-medium);
        margin-bottom: var(--margin-base);
    }
    .token-w {
        width: calc(50% - var(--padding-medium));
        vertical-align: top;
    }

    .token-w:nth-of-type(2n) {
        margin-right: 0;
    }
    .onboarding {
        width: 100%;
    }
    .onboarding img {
        max-width: 60vw;
    }
}

/* Responsive */
@media only screen and (max-width: 700px) {
    .token-w {
        width: 100%;
        margin-right: 0;
    }
    .onboarding {
        padding-top: 0;
    }
    .onboarding img {
        max-width: 100%;
        width: 80%;
    }
}

.modal-clearfix {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(64,64,64,.6);
    z-index: 1;
    display: flex;
}

.modal {
    position: relative;
    display: block;
    margin: auto;
    width: 600px;
    max-width: 90%;
    background: #fff;
    padding: var(--padding-large);
    padding-top: var(--padding-base);
    border-radius: 4px;
}
.modal p.title {
    font-size: var(--font-size-large);
}
input {
    outline: none;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 0;
    border-bottom: 1px solid #666;
    padding: var(--padding-small) 0;
    margin-bottom: var(--margin-base);
    font-size: var(--font-size-small);
}
button {
    position: relative;
    display: block;
    outline: none;
    color: #fff;
    background: var(--text-color);
    border: none;
    /* width: 100%; */
    cursor: pointer;
    padding: var(--padding-small) var(--padding-base);
    text-transform: uppercase;
    font-weight: bold;
    font-size: var(--font-size-tiny);
    border-radius: 6px;
    margin-top: var(--margin-base);
    box-shadow: 0px 4px 8px rgba(64,64,64,.2);
    /* float: right; */
    line-height: 19px;

    -webkit-transition: all ease-in-out .2s;
    -moz-transition: all ease-in-out .2s;
    -o-transition: all ease-in-out .2s;
    transition: all ease-in-out .2s;
}

button:active {
    box-shadow: none;
    transform: scale(.96);
}

.error {
    padding: var(--padding-base);
    border-radius: 6px;
    line-height: 20px;
    background: #FFECE7;
    color: var(--text-color-red);
    font-size: var(--font-size-small);
    font-weight: 500;
    box-shadow: 0px 0px 8px rgba(64,64,64,.1);
    margin: var(--margin-base) 0;
    margin-bottom: calc(var(--margin-base) * 2);
}

.spinner {
    width: 40px;
    height: 40px;
    margin: var(--margin-base) auto;
    background-color: #999;

    border-radius: 100%;  
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
    -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% { 
    -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
    -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}
</style>
