<template lang="pug">
.wrapper
    .token.animated.fadeIn(v-if="token")
        p.description {{ token['description'] }}
        input.inline-block(:type="show ? 'text' : 'password'", :value="token['token']", disabled)
        .btn.inline-block(v-on:click="toggle")
            i.material-icons-outlined {{ show ? 'visibility_off' : 'visibility' }}

    //- Content loader
    .token.token-skt(v-if="!token")
        p.description.skt
        .skt.input
</template>

<script>
export default {
    data: () => ({
        show: false
    }),
    props: {
        token: {
            type: Object
        }
    },
    methods: {
        toggle() {
            this.show = !this.show;
        }
    }
};
</script>

<style scoped>

* {
    --avatar-size: 40px;
    text-decoration: none;
}

.token {
    padding: var(--padding-base);
    padding-right: 6px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(64, 64, 64, .08);

    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.token .description {
    width: 95%;
    margin: 0;
    font-weight: 600;
    margin-bottom: var(--margin-base);
}

.token input {
    background: #f4f4f4;
    outline: none;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 8px;
    width: calc(100% - 40px);
    font-size: var(--font-size-small);
    font-family: monospace;
}

.token input,
.token .btn{
    vertical-align: middle;
}
.token .btn {
    width: 40px;
    text-align: center;
}
.token i {
    cursor: pointer;
    font-size: var(--font-size-small);
}

.token-skt {
    border-color: #f6f7f8;
    border-width: 2px;
    box-shadow: none;
}
.token-skt p.description {
    height: 23px;
    width: 30%;
}
.token-skt .input {
    height: 29px;
    width: 90%;
}
</style>