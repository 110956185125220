import axios from 'axios';
import { getToken } from '@/services/user-service';

export const getAPITokens = async () => {
    return (await axios.get('/api/v0/token', {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    }))['data']['data'];
};

export const postAPIToken = async (description) => {
    const token = (await axios.post('/api/v0/token', {
        description
    }, {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    }))['data']['data'];

    return token;
};
